<div
  class="flex flex-col whitespace-pre-wrap w-[250px] h-[300px] desktopLarge:w-[300px] desktopLarge:h-[350px] gap-10"
>
  <span
    class="text-white bg-kamadoor-orange text-xl text-center h-[30px] desktopLarge:h-[50px]"
    >{{ label }}</span
  >

  <a
    [href]="url"
    [ariaLabel]="label"
    class="h-[230px] desktopLarge:h-[260px] bg-white pt-2 desktopLarge:pt-6 pb-6 px-3 flex flex-col transition ease-in-out duration-300 hover:-translate-y-1 hover:scale-110"
  >
    <img class="h-[131px] object-contain" [src]="src" [alt]="alt" />
    <div class="flex flex-col h-full">
      <span class="bg-kamadoor-orange py-1 text-white text-center text-lg"
        >Zobacz ofertę</span
      >
      <span class="text-black text-center">{{ description }}</span>
    </div>
  </a>
</div>
